<template>
<div class="not">

</div>
</template>

<script>
export default {
  name: "notfound"
}
</script>

<style scoped>
.not{
  width: 100%;
  height: 100vh;
  background: url("../assets/404.png") center no-repeat;

}

</style>
